import React from 'react'
import * as styles from "@mui/material/styles";
import IndexLayout from '../layouts';
import { 
  IndexHero,
  IndexFeatures,
  IndexProducts,
  IndexHabi,
  IndexCarousel,
  IndexCTA
} from '../components/Index/';
import Footer from '../components/Footer';
import SEO from '../components/SEO';

import '../styles/styles.scss'


interface IndexProps {
  location: Location
}

const index: React.FC<IndexProps> = ({ location }) => {

  const content = (
    <>
      <SEO title="Partner tecnológico Transformación Digital - Ingenio Kushki"
      description="En Ingenio Kushki somos el partner tecológico dedicado a impulsar la transformación digital con la finalidad de agilizar y facilitar el pago de documentos." />

      <div className="ingenio-bg-hero">
        <IndexHero />
      </div>

      <IndexFeatures />

      <IndexHabi />

      <div className="ingenio-bg-products">
        <IndexProducts />
      </div>

      <IndexCarousel />
      
      {/* <IndexCTA /> */}

      <div className="ingenio-bg-footer">
        <Footer />
      </div>
    </>
  )

  return (
    <IndexLayout location={location} content={content}  />
  )
}

export default index